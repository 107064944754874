import './index.scss'

const w = 300 // canvas width
const h = 140 // canvas height
const fx = w / 2 // original focus x
const fy = 40 // original focus y
const f = { x: fx, y: fy } // change on mouse move
const maxDx = 20
const maxDy = (maxDx / w) * h
const r = 1 / 2 // ratio to cut intersection

const color0 = { r: 49, g: 64, b: 140 }
const color1 = { r: 255, g: 140, b: 0 }
const color2 = { r: 255, g: 255, b: 255 }
const colors = [color0, color1, color2]
const colorA = 0.1
const rgba0 = `rgba(${color0.r},${color0.g},${color0.b},${colorA})`

const bg = document.getElementById('logo-bg') as any as SVGElement
const shapes: {
  x: number
  y: number
  p?: SVGPolygonElement
}[][] = [
  [
    { x: 20, y: 0 },
    { x: 20, y: 140 },
  ],
  [
    { x: 72, y: 0 },
    { x: 40, y: 32 },
  ],
  [
    { x: 100, y: 0 },
    { x: 100, y: 140 },
  ],
  [
    { x: 120, y: 70 },
    { x: 140, y: 70 },
  ],
  [
    { x: 160, y: 70 },
    { x: 180, y: 70 },
  ],
  [
    { x: 200, y: 70 },
    { x: 220, y: 70 },
  ],
  [
    { x: 240, y: 0 },
    { x: 240, y: 32 },
  ],
  [
    { x: 240, y: 80 },
    { x: 240, y: 140 },
  ],
  [
    { x: 260, y: 60 },
    { x: 240, y: 80 },
  ],
  [
    { x: 280, y: 0 },
    { x: 280, y: 80 },
  ],
  [
    { x: 268, y: 140 },
    { x: 300, y: 108 },
  ],
]

const draw = () => {
  shapes.forEach(coords => {
    coords.forEach((c0, idx) => {
      if (idx === coords.length - 1) {
        return
      }
      const x0 = c0.x + r * (f.x - c0.x)
      const ic0 = intersection(c0.x, c0.y, f.x, f.y, x0, 0, x0, h)
      const c1 = coords[idx + 1]
      const x1 = c1.x + r * (f.x - c1.x)
      const ic1 = intersection(c1.x, c1.y, f.x, f.y, x1, 0, x1, h)
      const xc = (c0.x + c1.x + ic0.x + ic1.x) / 4
      const yc = (c0.y + c1.y + ic0.y + ic1.y) / 4
      const ints = [c0, c1, ic0, ic1]
        .map(c => ({ c, v: Math.atan2(c.x - xc, c.y - yc) }))
        .sort((a, b) => a.v - b.v)
        .map(i => i.c)
      if (!c0.p) {
        c0.p = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
        c0.p.setAttribute('fill', rgba0)
        bg.appendChild(c0.p)
      }
      c0.p.setAttribute('points', ints.map(c => c.x + ',' + c.y).join(' '))
    })
  })
}

const intersection = (
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
) => {
  const a0 = (y1 - y0) / (x1 - x0)
  const b0 = y0 - a0 * x0
  const a2 = (y3 - y2) / (x3 - x2)
  const b2 = y2 - a2 * x2
  const x = x2 === x3 ? x2 : (b2 - b0) / (a0 - a2)
  const y = a0 * x + b0
  return { x, y }
}

draw()

const onMouseMove = (x: number, y: number) => {
  const ww = window.innerWidth / 2
  const wh = window.innerHeight / 2
  f.x = fx + ((x - ww) / ww) * maxDx
  f.y = fy + ((y - wh) / wh) * maxDy
  draw()
}

window.addEventListener('mousemove', e => {
  const x = e.pageX
  const y = e.pageY
  requestAnimationFrame(() => onMouseMove(x, y))
})
window.addEventListener('touchstart', e => {
  const x = e.touches[0].pageX
  const y = e.touches[0].pageY
  onMouseMove(x, y)
})
window.addEventListener('touchmove', e => {
  const x = e.touches[0].pageX
  const y = e.touches[0].pageY
  requestAnimationFrame(() => onMouseMove(x, y))
})

//
const delay = 3000
const interval = 2000
type Rgb = {
  r: number
  g: number
  b: number
}
// linear interpolation between two values a and b
// u controls amount of a/b and is in range [0.0,1.0]
const lerp = (a: number, b: number, u: number) => {
  return (1 - u) * a + u * b
}
const stepDuration = 1000 / 60
const animate = (
  start: Rgb,
  end: Rgb,
  duration: number,
  stepFn: (r: number, g: number, b: number) => void,
  fn: () => void,
) => {
  const steps = duration / stepDuration
  const stepU = 1 / steps
  let u = 0
  const intervalFn = () => {
    if (u >= 1) {
      fn()
      return
    }
    u += stepU
    const [cr, cg, cb] = ['r', 'g', 'b']
      .map(k => lerp(start[k], end[k], u))
      .map(Math.round)
      .map(v => (v < 0 ? 0 : v))
      .map(v => (v > 255 ? 255 : v))
    stepFn(cr, cg, cb)
    requestAnimationFrame(intervalFn)
  }
  requestAnimationFrame(intervalFn)
}
const animateLoop = (i: number) => {
  const i2 = i === colors.length - 1 ? 0 : i + 1
  animate(
    colors[i],
    colors[i2],
    interval,
    (cr: number, cg: number, cb: number) => {
      shapes.forEach(coords => {
        coords.forEach(c => {
          if (!c.p) {
            return
          }
          c.p.setAttribute('fill', `rgba(${cr},${cg},${cb},${colorA})`)
        })
      })
    },
    () => {
      setTimeout(() => animateLoop((i + 1) % colors.length), delay)
    },
  )
}
setTimeout(() => animateLoop(0), delay)

const scale = 0.25
const scr = (1 - scale) / scale
const fpY = 7
const bg1 = document.getElementById('bg1') as HTMLDivElement
const bg2 = document.getElementById('bg2') as HTMLDivElement
const bg2m = document.getElementById('bg2-menu') as HTMLDivElement
const container = document.getElementById('container') as HTMLDivElement
window.addEventListener('scroll', () => {
  const wh = window.innerHeight
  const sc = document.scrollingElement?.scrollTop || 0
  let percentage = sc / wh
  if (percentage > 1) {
    percentage = 1
  }
  // bg1.style.bottom = `${100*percentage}%`
  void bg1
  bg2m.style.opacity = `${percentage < 0.99 ? 0 : 0.5}`
  bg2.style.top = `${100 * (1 - percentage)}%`
  container.style.top = `${35 * (1 - percentage)}%`
  container.style.right = `${50 * (1 - percentage)}%`
  container.style.transform = `scale(${
    1 - scr * scale * percentage
  }) translateX(${50 * (1 + percentage * (scr - 1 - 0.4))}%) translateY(${
    -percentage * (70 * scr - (fpY * 1) / scale)
  }px)`
})
